<template>
  <div class="AppAbout">
    <div class="AppAbout__block">
      <app-about-block
        :isFirst="true"
        v-for="aboutBlock in aboutBlocks__1"
        :key="aboutBlock.id"
        :title="getSortedTexts[aboutBlock.title]['text_' + selectedLang]"
        :subtitleFirstPart="getSortedTexts[aboutBlock.subtitleFirstPart]['text_' + selectedLang]"
        :subtitleSecondPart="getSortedTexts[aboutBlock.subtitleSecondPart]['text_' + selectedLang]"
        :imageUrl="aboutBlock.imageUrl"
      />
      <app-about-block
        :isReverse="true"
        v-for="aboutBlock in aboutBlocks__2"
        :key="aboutBlock.id"
        :title="getSortedTexts[aboutBlock.title]['text_' + selectedLang]"
        :subtitleFirstPart="getSortedTexts[aboutBlock.subtitleFirstPart]['text_' + selectedLang]"
        :subtitleSecondPart="getSortedTexts[aboutBlock.subtitleSecondPart]['text_' + selectedLang]"
        :imageUrl="aboutBlock.imageUrl"
      />
      <app-about-block
        v-for="aboutBlock in aboutBlocks__3"
        :key="aboutBlock.id"
        :title="getSortedTexts[aboutBlock.title]['text_' + selectedLang]"
        :subtitleFirstPart="getSortedTexts[aboutBlock.subtitleFirstPart]['text_' + selectedLang]"
        :subtitleSecondPart="getSortedTexts[aboutBlock.subtitleSecondPart]['text_' + selectedLang]"
        :imageUrl="aboutBlock.imageUrl"
      />
    </div>
  </div>
</template>

<script>
import AppAboutBlock from '../components/TheAbout/AppAboutBlock.vue';
import {mapGetters} from "vuex";

export default {
  name: '',
  components: {
    AppAboutBlock,
  },
  data() {
    return {
      aboutBlocks__1: [
        {
          id: 1,
          title: 0,
          subtitleFirstPart: 1,
          subtitleSecondPart: 2,
          imageUrl: 'about__1',
        },
      ],
      aboutBlocks__2: [
        {
          id: 2,
          title: 3,
          subtitleFirstPart: 4,
          subtitleSecondPart: 5,
          imageUrl: 'about__2',
        },
      ],
      aboutBlocks__3: [
        {
          id: 3,
          title: 6,
          subtitleFirstPart: 7,
          subtitleSecondPart: 8,
          imageUrl: 'about__3',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },
};
</script>

<style lang="scss">
.AppAbout {
  &__block {
    padding-bottom: 120px;

    @include media-d-m($screen-netbook) {
      padding-bottom: 0;
    }
  }
}
</style>
