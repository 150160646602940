<template>
  <div class="AppAboutBlock">
    <div class="AppAboutBlock__inner container" :class="{ reverse: isReverse }">
      <div
        class="AppAboutBlock__banner AppAboutBlock--image-width"
        data-aos="fade-down"
      >
        <app-title class="AppAboutBlock__title title--medium">{{
          title
        }}</app-title>
        <app-subtitle class="AppAboutBlock__subtitle subtitle--medium">{{
          subtitleFirstPart
        }}</app-subtitle>
        <app-subtitle class="AppAboutBlock__subtitle subtitle--medium">{{
          subtitleSecondPart
        }}</app-subtitle>
      </div>
      <div
        class="AppAboutBlock__image"
        :class="{ first: isFirst }"
        :style="{
          'background-image':
            'url(' +
            require('@/assets/images/about/' + imageUrl + '.png') +
            ')',
        }"
        :data-aos="isReverse ? 'fade-right' : 'fade-left'"
      ></div>
    </div>
  </div>
</template>

<script>
import AppTitle    from '../AppTitle.vue';
import AppSubtitle from '../AppSubtitle.vue';
export default {
  name: 'AppAboutBlock',
  components: {
    AppTitle,
    AppSubtitle,
  },
  props: {
    title: String,
    subtitleFirstPart: String,
    subtitleSecondPart: String,
    imageUrl: String,
    isReverse: Boolean,
    isFirst: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.AppAboutBlock {
  overflow: hidden;

  &__inner {
    @include flex(space-between, center, row, nowrap);
    @include media-d-m($screen-netbook) {
      @include flex(center, center, column-reverse, nowrap);
    }

    &.reverse {
      @include flex(space-between, center, row-reverse, nowrap);
      @include media-d-m($screen-netbook) {
        @include flex(center, center, column-reverse, nowrap);
      }
    }
  }
  &__title {
    max-width: 555px;
    margin-bottom: 65px;

    @include media-d-m($screen-netbook) {
      margin-bottom: 40px;
    }
  }

  &__subtitle {
    max-width: 500px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    &:last-of-type {
      @include media-d-m($screen-netbook) {
        margin-bottom: 93px;
      }
    }
  }

  &__image {
    max-width: 700px;
    width: 100%;
    height: 800px;
    overflow: hidden;
    margin-right: -200px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-d-m($screen-desktop-large) {
      margin-right: -400px;
    }

    @include media-d-m($screen-netbook) {
      width: calc(100% + 60px);
      height: 750px;
      margin-bottom: 20px;
    }

    &:not(:nth-child(odd)) {
      margin-left: -200px;

      @include media-d-m($screen-desktop-large) {
        margin-left: -400px;
      }
    }

    &.first {
      height: 900px;
      @include media-d-m($screen-netbook) {
        height: 400px;
      }
    }
  }
}
</style>
